var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"content-container"},[_c('svg',{staticClass:"mt-12 mb-5",staticStyle:{"justify-content":"center","align-items":"center"},attrs:{"width":"40","height":"40","viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"40","height":"40","rx":"8","fill":"#DD3333"}}),_c('path',{attrs:{"d":"M13.7279 13.728L27 27.0001","stroke":"white","stroke-width":"3","stroke-linecap":"round"}}),_c('path',{attrs:{"d":"M27 13.728L13.7279 27.0001","stroke":"white","stroke-width":"3","stroke-linecap":"round"}})]),_c('h1',{staticClass:"redoSignature text-center mx-5",style:({
        color: _vm.WEBAPPPRO_TEXT_COLOR_1,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
        marginBottom: '32px',
        fontWeight: 'regular',
        'text-transform': 'none',
      })},[_vm._v(" Je refais ma signature ")]),_c('p',{staticClass:"text-center",style:({
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        color: _vm.WEBAPPPRO_TEXT_COLOR_2,
      })},[_c('strong',{style:({
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
          paddingTop: '10px',
        })},[_vm._v("Vous êtes invité à refaire votre signature.")])]),_c('br'),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-card',{staticClass:"card mb-6 mx-auto pt-3",style:({
          backgroundColor: _vm.WEBAPPPRO_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_CARD_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
          width: '327px',
        })},[_c('div',{staticClass:"px-5",staticStyle:{"align-items":"center","display":"flex","flex-direction":"row"}},[_c('svg',{staticStyle:{"margin-top":"-60px"},attrs:{"width":"34","height":"34","viewBox":"0 0 34 34","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"34","height":"34","rx":"8","fill":_vm.WEBAPPPRO_FILL_COLOR_FOR_TREATMENT}}),_c('path',{attrs:{"d":"M18.8552 9.07563C18.4655 8.40544 17.7733 8 16.998 8C16.2226 8 15.5304 8.40251 15.1407 9.07563L7.2922 22.6806C6.9026 23.3508 6.9026 24.1509 7.2922 24.824C7.67693 25.4942 8.37215 25.8947 9.14745 25.8947H24.8526C25.6249 25.8947 26.3201 25.4942 26.7078 24.824C27.0974 24.1538 27.0974 23.3537 26.7078 22.6806L18.8593 9.07563H18.8552ZM16.998 22.3985C16.5234 22.3985 16.1377 22.0155 16.1377 21.5407C16.1377 21.0659 16.5224 20.68 16.998 20.68C17.4725 20.68 17.8582 21.063 17.8582 21.5407C17.8582 22.0184 17.4735 22.3985 16.998 22.3985ZM18.0681 15.4837L17.6131 19.368C17.5184 20.0958 16.4707 20.088 16.3808 19.368L15.9258 15.4837C15.8233 13.8903 18.1658 13.8903 18.0681 15.4837Z","fill":_vm.WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT}})]),_c('p',{staticClass:"px-4",style:({
              color: _vm.WEBAPPPRO_TEXT_COLOR_2,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              'font-weight': 'medium',
              'line-height': '20px',
            })},[_vm._v(" La signature doit être conforme pour être validée par l'administration. ")])])])],1),_c('br'),_c('v-btn',{staticClass:"btn centered-button mt-2",style:({
        color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
        boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
        borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
        'text-transform': 'none',
        padding: '25px 100px',
        'font-weight': 'bold',
        'line-height': '18px',
        'letter-spacing': '0em',
        'text-align': 'center',
        marginTop: '20px',
        width: '327px',
        height: '52px',
        position: 'fixed' /* Fixe le bouton */,
        bottom: '20px' /* Le placer au bas de la page */,
      }),attrs:{"color":_vm.color1,"disabled":_vm.canvasEmpty,"loading":_vm.uploading},on:{"click":_vm.validate}},[_vm._v("Je refais ma signature")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }