<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="d-flex flex-column">
      <div class="content-container">
        <svg
          style="justify-content: center; align-items: center"
          class="mt-6 mb-5"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="8" fill="#DD3333" />
          <path
            d="M13.7279 13.728L27 27.0001"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M27 13.728L13.7279 27.0001"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>

        <h1
          class="redo text-center mx-5"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_1,
            fontSize: WEBAPPPRO_FONT_SIZE_1,
            fontFamily: WEBAPPPRO_FONT_FAMILY_1,
            marginBottom: '10px',
            fontWeight: 'regular',
            'text-transform': 'none',
          }"
        >
          Je refais ma photo
        </h1>
        <br />
        <div
          class="d-flex flex-column align-center"
          v-if="rejectStatus == 'rejected_photo'"
        >
          <div style="width: 100%; margin-top: 2px">
            <div
              :style="{
                color: WEBAPPPRO_TEXT_COLOR_2,
                fontSize: WEBAPPPRO_FONT_SIZE_3,
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                'font-weight': 'medium',
                overflowY: 'auto',
                maxHeight: '152px',
              }"
              class="px-4"
            >
              <ul>
                <li v-for="rejectItem in photoRejectReasons" :key="rejectItem">
                  {{ rejectItem.long_text }}
                </li>
              </ul>
            </div>
          </div>
          <v-card
            class="card mt-5 mb-6 mx-auto py-5"
            :style="{
              backgroundColor: WEBAPPPRO_COLOR_1,
              boxShadow: WEBAPPPRO_CARD_SHADOW_1,
              borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            }"
            width="327px"
          >
            <div
              style="align-items: center; display: flex; flex-direction: row"
              class="px-5"
            >
              <svg
                style="margin-top: -60px"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="34"
                  height="34"
                  rx="8"
                  :fill="WEBAPPPRO_FILL_COLOR_FOR_TREATMENT"
                />
                <path
                  d="M18.8552 9.07563C18.4655 8.40544 17.7733 8 16.998 8C16.2226 8 15.5304 8.40251 15.1407 9.07563L7.2922 22.6806C6.9026 23.3508 6.9026 24.1509 7.2922 24.824C7.67693 25.4942 8.37215 25.8947 9.14745 25.8947H24.8526C25.6249 25.8947 26.3201 25.4942 26.7078 24.824C27.0974 24.1538 27.0974 23.3537 26.7078 22.6806L18.8593 9.07563H18.8552ZM16.998 22.3985C16.5234 22.3985 16.1377 22.0155 16.1377 21.5407C16.1377 21.0659 16.5224 20.68 16.998 20.68C17.4725 20.68 17.8582 21.063 17.8582 21.5407C17.8582 22.0184 17.4735 22.3985 16.998 22.3985ZM18.0681 15.4837L17.6131 19.368C17.5184 20.0958 16.4707 20.088 16.3808 19.368L15.9258 15.4837C15.8233 13.8903 18.1658 13.8903 18.0681 15.4837Z"
                  :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
                />
              </svg>
              <p
                :style="{
                  color: WEBAPPPRO_TEXT_COLOR_2,
                  fontSize: WEBAPPPRO_FONT_SIZE_3,
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  'font-weight': 'medium',
                  'line-height': '20px',
                }"
                class="px-4"
              >
                La photo doit être conforme pour être validée par
                l'administration.
              </p>
            </div>
          </v-card>
        </div>
        <br />
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          class="btn centered-button mt-2"
          :disabled="canvasEmpty"
          @click="validate"
          :loading="uploading"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            padding: '25px 100px',
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '20px',
            width: '327px',
            height: '52px',
            position: 'fixed' /* Fixe le bouton */,
            bottom: '20px' /* Le placer au bas de la page */,
          }"
          >Je refais ma photo
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'WelcomeRedoPhoto',
  data() {
    return {
      rejectStatus: null,
      photoRejectReasons: null,

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      timelineColor: localStorageService.getStyle(
        location.hostname,
        'timelineColor',
      ),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      color2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      items: [],
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      WEBAPPPRO_FILL_COLOR_FOR_TREATMENT: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
      ),
      WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
      ),
    }
  },
  methods: {
    validate() {
      this.$router.push({ name: 'ShootPhoto' })
    },
  },
  mounted() {
    if (
      localStorageService.getValue('shortliveApiKey') === undefined ||
      localStorageService.getValue('rejectStatus') === undefined ||
      localStorageService.getValue('photoRejectReasons') === undefined
    ) {
      this.$router.push({ name: 'NotAuthorized' })
    } else {
      this.rejectStatus = localStorageService.getValue('rejectStatus')
      this.photoRejectReasons =
        localStorageService.getValue('photoRejectReasons')
    }
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
@media only screen and (min-width: 320px) and (max-width: 360px) and (max-height: 639px) {
  .redo {
    font-size: 28px !important;
  }
  .btn,
  .card {
    width: 270px !important;
  }
}
</style>
